<template>
    <div class="client-info">
        <div class="first-row">
            <input
                name="name"
                placeholder="Name"
                class="input-name"
                :class="{ 'has-error': errors?.name }"
                v-model="name"
            />
            <vue-tel-input
                v-model="phone"
                name="phone"
                mode="international"
                :dynamicPlaceholder="true"
                :validCharactersOnly="true"
                :inputOptions="{ showDialCode: true, maxlength, name: 'phone' }"
                :class="{ valid, 'not-valid': !valid, 'has-error': errors?.phone }"
                @validate="telControl"
            />
            <input
                name="email"
                placeholder="email@example.com"
                class="input-email"
                :class="{ 'has-error': errors?.email }"
                v-model="email"
            />
        </div>
        <div class="second-row">
            <choose-properties
                @typeSelected="type = $event"
                @travelersSelected="travelers = $event"
                :propType="type"
                :propTravelers="travelers"
            />
            <orange-filled-button
                class="search-submit"
                :buttonText="`Get Free Quote`"
                :click="
                    () =>
                        $emit('button-clicked', {
                            phone_valid: valid,
                            cabin_class: type
                                .toLowerCase()
                                .replace(/ /g, '_')
                                .replace(/_class/, ''),
                            passengers: travelers,
                            client: {
                                name,
                                phone: phone?.replace(/ /g, ''),
                                email,
                            },
                        })
                "
            />
        </div>
        <div class="privacy-text">
            By submitting, you agree to our <router-link to="/privacy">Privacy Policy</router-link> and consent to
            receive SMS.
        </div>
    </div>
</template>

<script>
// Components
import { VueTelInput } from 'vue-tel-input'
import OrangeFilledButton from '@/common/UI/Buttons/OrangeFilledButton.vue'
import ChooseProperties from './ChooseProperties.vue'

// Helpers
import { telInputControl } from '@/helpers/functions'

export default {
    components: {
        VueTelInput,
        ChooseProperties,
        OrangeFilledButton,
    },
    props: {
        errors: {
            type: Object,
            default: () => ({
                phone: null,
                email: null,
                name: null,
            }),
        },
    },
    data() {
        return {
            name: '',
            email: '',
            phone: null,
            valid: null,
            maxlength: 25,
            type: 'Business Class',
            travelers: {
                adult: 1,
                child: 0,
                infant: 0,
            },
        }
    },
    methods: {
        telControl(validation) {
            telInputControl(validation, this)
        },
    },
}
</script>
<style lang="scss">
.search-submit {
    box-shadow: 0 0 8px #101214;

    button {
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1.2px;
    }
}

.privacy-text {
    font-family: 'Inter', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    padding-inline: 8px;
    padding-block: 4px;
    border-radius: 6px;
    margin-top: 8px;
    color: #dadde0;
    background-color: #1a1d1f;

    a {
        color: #800020;
    }
}
</style>
